.container {
  background: #fff;

  &.noBackground {
    background: none;
  }
}

@media (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
