@import '../../colors.scss';

a.navLink {
  color: $tertiary;
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;

  &:active {
    text-decoration: underline;
  }

  &.active,
  &:hover,
  &.fill {
    background: $background;
    border-top-color: $pane;
    border-left-color: $pane;
    border-bottom-color: $extra;
    border-right-color: $extra;
  }

  &.active,
  &.fill {
    background: $pane;
  }

  &:hover {
    color: $secondary;
  }
}
