.input {
  display: inline-block;
  min-width: 1em;
  user-select: none;
  cursor: ew-resize;
  background: none !important;
  border-bottom: 1px dashed #000 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  outline: none;
  text-align: center;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0 0.5em;
  padding: 0;
}

.inputWithButtons {
  min-width: 2em;
  cursor: default;

  span {
    flex: 1;
    cursor: ew-resize;
    display: inline-block;
    min-width: 1em;
  }
  button {
    font-size: 1em;
    line-height: inherit;
    margin: 1px;
  }
}
