@import './colors.scss';

body,
html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: $background;
  color: #101840;
}

* {
  box-sizing: border-box;
}
